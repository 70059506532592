import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'

const Page = ({ data, pageContext }) => {
  const pageContent = data.prismic.allPages.edges[0]
  if (!pageContent) return null
  const page = pageContent.node

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node
  // const instagramData = data.allInstaPost.edges[0].node.graphql ? data.allInstaPost.edges[0].node.graphql : data.allInstaPost.edges[1].node.graphql
  // console.log( 'data.prismic.allInstaPost', instagramData )

  if (page) {
    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
      >
        <SliceZone
          slices={page.body}
          posts={data.prismic.allPosts.edges}
          books={data.prismic.allBooks.edges}
          allInstaPost={null}
        />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query pageQuery($uid: String, $lang: String) {
  prismic {
    allPages(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          body {
            ... on PRISMIC_PageBodyBooks_list {
              label
              type
              fields{
                book{
                  _linkType
                  __typename
                }
              }
            }
            
            ... on PRISMIC_PageBodyFull_width_image {
              type
              label
              primary {
                image
              }
            }
            
           
            ... on PRISMIC_PageBodyText_info {
              type
              label
              primary {
                section_title
                left_column_text
                right_column_text
              }
            }
            ... on PRISMIC_PageBodyText {
              type
              label
              primary{
                text
              }
            }
            ... on PRISMIC_PageBodyInstagram_feed {
              type
              label
            }
            ... on PRISMIC_PageBodyLatest_posts {
              type
              label
              primary{
                display_thumbnail
              }
            }
          }
        }
      }
    }
    allPosts(
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          title
          intro
          cover
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default Page

// allInstaPost {
//   edges {
//     node {
//       graphql {
//         user {
//           edge_owner_to_timeline_media {
//             edges {
//               node {
//                 thumbnail_src
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
